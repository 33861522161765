//noinspection ES6UnusedImports

import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import "./App.scss";

/* eslint-disable @typescript-eslint/no-unused-vars */
import HoldingPage from "./components/holdingPage/HoldingPage";


const Home = lazy(() => import ("./components/home/Home"));
const About = lazy(() => import ("./components/about/About"));
const Services = lazy(() => import ("./components/services/Services"));
const Projects = lazy(() => import ("./components/projects/Projects"));


function App() {
  return (
    <>
      <Suspense fallback="">
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/services" element={<Services />} />
            <Route path="/projects" element={<Projects />} />
          </Routes>
        </Router>
      </Suspense>
    </>
  );
}

export default App;
