//noinspection JSUnusedGlobalSymbols

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";


i18n.options.lng = localStorage.getItem("be-vsd-lang") || "en";

//noinspection JSIgnoredPromiseFromCall
i18n.use(Backend)
.use(initReactI18next)
.init({
  lng: i18n.options.lng,
  interpolation: {
    escapeValue: false
  }
});

export default i18n;
