import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import apiKeysToCamelCase from "../utility/apiKeysToCamelCase";
import Project from "../models/Project";
import ProjectType from "../models/ProjectType";

export const api = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_BASE_URL
  }),
  endpoints: (builder) => ({
    getLanguages: builder.query<any, void>({
      query: () => "languages"
    }),
    getProjects: builder.query<Project[], void>({
      query: () => "projects",
      transformResponse: (projects: any) => {
        const keyProcessedProjects: Project[] = apiKeysToCamelCase(projects);

        if(keyProcessedProjects.length > 0) {
          return keyProcessedProjects.sort((a: Project, b: Project) => (a.number < b.number) ? 1 : -1);
        } else {
          return keyProcessedProjects;
        }
      }
    }),
    getProjectTypes: builder.query<ProjectType[], void>({
      query: () => "project-types",
      transformResponse: (types: any) => apiKeysToCamelCase(types)
    })
  })
});

export const {
  useGetLanguagesQuery,
  useGetProjectsQuery,
  useGetProjectTypesQuery
} = api;
