import { createSlice, PayloadAction } from "@reduxjs/toolkit";


export interface ConfigState {
  language: string;
}


const initialState: ConfigState = {
  language: "en"
};


export const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: {
    setLanguage: (state, action: PayloadAction<string>) => {
      state.language = action.payload;
    }
  }
});


export const {
  setLanguage
} = configSlice.actions;
export default configSlice.reducer;
