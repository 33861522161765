import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import Project from "../../models/Project";


export interface GalleryState {
  selectedProject: Project | null;
  selectedImageIndex: number;
  showImageView: boolean;
}


const initialState: GalleryState = {
  selectedProject: null,
  selectedImageIndex: 0,
  showImageView: false
};


export const gallerySlice = createSlice({
  name: "gallery",
  initialState,
  reducers: {
    setSelectedProject: (state, action: PayloadAction<Project>) => {
      state.selectedProject = action.payload;
    },
    setSelectedImageIndex: (state, action: PayloadAction<number>) => {
      state.selectedImageIndex = action.payload;
    },
    setShowImageView: (state, action: PayloadAction<boolean>) => {
      state.showImageView = action.payload;
    }
  }
});


export const {
  setSelectedProject,
  setSelectedImageIndex,
  setShowImageView
} = gallerySlice.actions;
export default gallerySlice.reducer;
